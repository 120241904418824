import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import { Helmet } from 'react-helmet'
import MultiStepForm from 'components/forms/multi-step-form'
import { steps } from 'components/forms/service'
import Layout from 'components/layout'
import Content from 'components/content'

const ScheduleServicePage = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Schedule Service | Hutson Inc</title>
        <meta
          name='description'
          content="Need to schedule a service on your equipment? Fill out this short form and we'll schedule an appointment to have you back up and running in no time."
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Service',
                'item': 'https://www.hutsoninc.com/service/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Schedule Service',
                'item': 'https://www.hutsoninc.com/service/schedule-service/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroImage title='Schedule Service' image={heroImage} />

      <Content>
        <Details>
          Please complete the information in each of the tabs to schedule service. We will contact
          you shortly regarding the services requested. For urgent after hours service, please
          contact your nearest Hutson store after hours phone number. You can visit our{' '}
          <Link to='/locations/'>locations</Link> page to find the store nearest you.
        </Details>

        <MultiStepForm steps={steps} name='Schedule Service' />
      </Content>
    </Layout>
  )
}

const Details = styled.p`
  font-size: 1.05em;
  font-weight: normal;
  margin: 0 0 30px;
  text-transform: none;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "service-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default ScheduleServicePage
